import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import './login.scss';
import {doLogin} from './service'
import * as Utils from 'components/common/utils'
import { useNavigate } from "react-router-dom";

const Login = (props) => {
    const COOKIE_NAME = 'accessToken';
    const [code, setCode] = useState("");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            const accessToken = Utils.getCookie(COOKIE_NAME);
            if(accessToken) {
                navigate('/untagged-videos');
            }
        }, 100);        
    },);

    const onCodeChange = (event) => {
        setCode(event.target.value.trim());
    }

    const submit = () => {
        if(code) {        
            Utils.eraseCookie(COOKIE_NAME)
            Utils.setCookie(COOKIE_NAME, code, 1)
            
            doLogin({token: code})
            .then((resp)=>{
                console.log("successful: ", resp);
                navigate('/untagged-videos');
            })
            .catch((err)=>{
                console.log("Error");
                alert(err.errorMessage || err.errorCode);
            });
        } else {
            alert("Please enter Code!");
        }
    }

    return (
        <>
            <div className="buttonContainer">
                <Button variant="primary" onClick={handleShow}>
                    Login
                </Button>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Enter Your Code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input style={{width: '100%'}} type="text" onChange={onCodeChange} placeholder="Code" />
                </Modal.Body>
                <Modal.Footer>                   
                    <Button variant="primary" onClick={submit}>
                        Login
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

Login.propTypes = {}

export default Login
