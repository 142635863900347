import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const QA_FIRE_BASE_CONFIG = {
    apiKey: "AIzaSyDs4SdO7YUexBCZmq2935HG9sd2o1GY0VQ",
    authDomain: "wagmi-qa.firebaseapp.com",
    databaseURL: "https://wagmi-qa-default-rtdb.firebaseio.com",
    projectId: "wagmi-qa",
    storageBucket: "wagmi-qa.appspot.com",
    messagingSenderId: "104781298024",
    appId: "1:104781298024:web:5db53ebc02fa10d42abedd",
    measurementId: "G-Q38XXZN51H"
};

const PROD_FIRE_BASE_CONFIG = {
    apiKey: "AIzaSyDjCEdM-qN7GAqB5_MdHjazOvJUN4qn5Kk",
    authDomain: "wagmi-338311.firebaseapp.com",
    projectId: "wagmi-338311",
    storageBucket: "wagmi-338311.appspot.com",
    messagingSenderId: "440167015126",
    appId: "1:440167015126:web:99577b5c678f1dec3b1f77",
    measurementId: "G-6Z5C58ZGLB"
};

let firebaseApp = null;

console.log(`ENV: ${window.config.mode}`)

if(window.config.mode === 'prod') {
    firebaseApp = firebase.initializeApp(PROD_FIRE_BASE_CONFIG);
} else {
    firebaseApp = firebase.initializeApp(QA_FIRE_BASE_CONFIG);
}

const db = firebaseApp.firestore()

const auth = firebase.auth()

export { db, auth }