import * as NetworkAdapter from '../common/axios';
import * as Utils from 'components/common/utils'

export const doLogin = (params) => {   
    Utils.setCookie("accessToken", params.token, 1)
    
    let url = '/user/login/token'
    return new Promise((resolve, reject) => {
        NetworkAdapter.fetchData.subsystem.post(null, 'platform', url, params, null, null, null, null, null, {
            onCallback: (resp) => {
                if (resp.success) {
                    resolve(resp.resp);
                } else {
                    reject(resp.resp);
                }
            }
        });
    })
}