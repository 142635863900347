import React from 'react'
import PropTypes from 'prop-types'
import Pagination from 'react-bootstrap/Pagination'
import { useState, useEffect } from 'react';
import {getUrlParams} from 'components/common/utils'

const PageSeq = (props) => {
    const [pageNo, setPageNo] = useState(1);    
    let displayPage = pageNo;

    if(getUrlParams().page) {
        displayPage = parseInt(getUrlParams().page);
    }

    useEffect(() => {
        props.updatePageNo(pageNo)
    }, [pageNo]);

    const onPageChange = (next) => {
        if(typeof(next) != 'undefined') {
            if(next) {
                //setPageNo(displayPage+1)    
                window.location.href = `${window.location.pathname}?page=${displayPage+1}`;
                console.log("Next page...");
            } else {
                if(displayPage != 1) {
                    //setPageNo(displayPage-1);
                    window.location.href = `${window.location.pathname}?page=${displayPage-1}`;
                }             
                console.log("Prev page...");
            }
        } else {
            console.log("Same page...");
        }
    }
    
    const disblePrev = () => {
        return displayPage == 1
    }

  return (
    <div>
        <Pagination>
            <Pagination.Prev onClick={() => onPageChange(false)} disabled={disblePrev()}/>
            <Pagination.Item onClick={() => onPageChange(undefined)}>{displayPage}</Pagination.Item>
            <Pagination.Next onClick={() => onPageChange(true)} disabled={props.disableNext} />
        </Pagination>
    </div>
  )
}

PageSeq.propTypes = {}

export default PageSeq
