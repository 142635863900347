import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import './modal.scss';

function SuccessModal(props) {
    const handleClose = () => props.hideModal();

    return (
        <Modal show={true} onHide={handleClose} className="sucess-model">
            <Modal.Header closeButton className="success-modal-header">
            <Modal.Title>{props.title || 'Error'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.message}</Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

SuccessModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    title: PropTypes.string,
    message: PropTypes.string.isRequired
}

export default SuccessModal
