import React from 'react'
import Multiselect from 'multiselect-react-dropdown';
import { useState, useEffect, useRef } from 'react';
import Table from 'react-bootstrap/Table'
import { Button } from 'react-bootstrap'
import constants from 'components/videoTagConatiner/constants'
import {updateVideoTag, rejectVideo, getTagSuggestions, addQC} from 'components/videoTagConatiner/service'
import Spinner from 'react-bootstrap/Spinner'
import {trimArray} from 'components/common/utils';
import HorizontalSelect from 'components/common/horizontalSelect/HorizontalSelect';

const VideoTag = (props) => {
  
  console.log("props: ", props);

  const [score, setScore] = useState(props.video && props.video.tag && props.video.tag.score ? [props.video.tag.score] : []);
  const [grades, setGrades] = useState(props.video?.tag?.grades || []);
  const [states, setStates] = useState(props.video?.tag?.states || []);
  const [languages, setLanguages] = useState(props.video?.tag?.languages || []);
  const [contentTypes, setContentTypes] = useState(props.video?.tag?.contentTypes || []);
  const [contentTargets, setContentTargets] = useState(props.video?.tag?.contentTargets || []);
  const [tier, setTier] = useState(props.video.tag && props.video.tag.tier ? [props.video.tag.tier] : []);
  const [creatorName, setCreatorName] = useState(props.video && props.video.tag && props.video.tag.creatorName ? [props.video.tag.creatorName] : []);
  const [others, setOthers] = useState(props.video && props.video.tag && props.video.tag.others ? [props.video.tag.others] : []);
  const [showSpinner, setShowSpinner] = useState(false);
  const [creatorsSuggection, setCreatorsSuggection] = useState([]);
  const [othersSuggestion, setOthersSuggestion] = useState([]);
  const videoRef= useRef();
  let reset = true

  const setPlayBack = () => {
    if(reset) {
      videoRef.current.playbackRate = 1.5;
    }
    reset = false;
  };

  const onGradeChange = (selectedList, removed) => {
    setGrades(selectedList)
    console.log("selectedList: ", selectedList);
  }

  const onStateChange = (selectedList, removed) => {
    setStates(selectedList)    
  }

  const onLanguageChange = (selectedList, removed) => {
    setLanguages(selectedList)  
  }

  const onContentTypesChange = (selectedList, removed) => {
    setContentTypes(selectedList)
  }

  const onContentTargetsChange = (selectedList, removed) => {
    setContentTargets(selectedList)
  }

  const onCreateChange = (selectedList, removed) => {
    setCreatorName(selectedList)
  }

  const onOthersChange = (selectedList, removed) => {
    setOthers(selectedList)
  }

  const onTierChange = (selectedList, removed) => {
    setTier(selectedList)
  }

  const onScoreChange = (selectedList) => {
    setScore(selectedList)
  }

  const saveVideoTag = () => {
    const params = {
      videoId: props.video.id,
      grades: trimArray(grades),
      states: trimArray(states),
      languages: trimArray(languages),
      contentTypes: trimArray(contentTypes),
      contentTargets: trimArray(contentTargets),
      creatorName: creatorName.length ? creatorName[0].trim() : "",
      others: others.length ? others[0].trim() : "",
      tier: tier.length ? tier[0] : "",
      score: score.length ? score[0] : ""  
    }
    console.log("params: ", params);
    updateVideoTag(params)
      .then((resp) => {
        console.log("successful: ", resp);
        props.showModal(true, "Video Tag Saved!");
        setTimeout(() => {
          window.location.reload()
        }, 500);
      })
      .catch((err) => {
        props.showModal(false, err);
        console.log("Error");
      }).finally(() => {
        setShowSpinner(false);
      })
    setShowSpinner(true);
  }


  const completeQC = () => {
    console.log("videoId: ", props.video.id);
    addQC(props.video.id)
      .then((resp) => {
        console.log("successful: ", resp);
        props.showModal(true, "Video Saved!");
        setTimeout(() => {
          window.location.reload()
        }, 500);
      })
      .catch((err) => {
        props.showModal(false, err);
        console.log("Error");
      }).finally(() => {
        setShowSpinner(false);
      })
    setShowSpinner(true);
  }

  const onRejectVideo = () => {
    rejectVideo(props.video.id)
    .then((resp)=>{
      console.log("successful: ", resp);
      props.showModal(true, "Video Rejected!");
      setTimeout(() => {
        window.location.reload()
      }, 500);
    })
    .catch((err)=>{
        props.showModal(false, err);
        console.log("Error");
    }) .finally(()=>{
      setShowSpinner(false);
    })
    setShowSpinner(true);
  }

  const onCreatorSearch = (query) => {
    if(query.length < 3) {
      return
    }
    let params = {
      query: query,
      type: 'CREATOR_NAME'
    };
    console.log(query); 
    getTagSuggestions(params)
    .then((resp)=>{
      console.log("successful onCreatorSearch: ", resp);
      setCreatorsSuggection(resp.responseObject)      
    })
    .catch((err)=>{
        props.showModal(false, err);
        console.log("Error");
    }) .finally(()=>{      
    })
  }

  const onOthersSearch = (query) => {
    if(query.length < 3) {
      return
    }
    let params = {
      query: query,
      type: 'OTHERS'
    }; 
    console.log(query);
    getTagSuggestions(params)
    .then((resp)=>{
      console.log("successful onOthersSearch: ", resp);
      setOthersSuggestion(resp.responseObject)   
    })
    .catch((err)=>{
        props.showModal(false, err);
        console.log("Error");
    }) .finally(()=>{      
    })    
  }

  const onCreatorTextChange = (e) => {
    setCreatorName([e.target.value])
  }

  const onOthersTextChange = (e) => {
    setOthers([e.target.value])
  }

  const allowAutPlay = () => {        
    return !props.tagged && !props.qced && !props.rejected
  }

  return (
    <tr>
      <td>{props.index + 1}</td>
      <td>
        <video width="225" height="400" autoPlay={allowAutPlay()} controls ref={videoRef}
        onCanPlay={() => setPlayBack()}>
          <source src={props.video?.videoInternalLink.split('?')[0]} />
        </video>
        <div>
          <a href={props.video.videoOriginalLink} target="blank">{props.video.videoOriginalLink}</a>
        </div>        
      </td>
      <td>
        <Table>
        <tr>
            <th>Video Id: </th>
            <td>
              <span>{props.video?.id}</span>
            </td>
          </tr>
          <tr>
            <th>Grades: </th>
            <td>             
                <HorizontalSelect 
                  options={constants.grades.value}
                  selectedValues={grades} 
                  onRemove={onGradeChange}
                  onSelect={onGradeChange}
                />
            </td>
          </tr>
          <tr>
            <th>Language: </th>
            <td>
              <HorizontalSelect 
                options={constants.langugae.value}
                selectedValues={languages}
                onRemove={onLanguageChange}
                onSelect={onLanguageChange}
                />
            </td>
          </tr>
          <tr>
            <th>State: </th>
            <td>
              <Multiselect
                  selectedValues={states}
                  isObject={false}    
                  showArrow={true}    
                  onRemove={onStateChange}
                  onSelect={onStateChange}
                  options={constants.state.value}/>
            </td>
          </tr>
          <tr>
            <th>Content Type: </th>
            <td>
              <Multiselect
                    isObject={false}    
                    selectedValues={contentTypes}    
                    showArrow={true}
                    onRemove={onContentTypesChange}
                    onSelect={onContentTypesChange}
                    options={constants.contentType.value}/>
            </td>
          </tr>
          <tr>
            <th>Content Target: </th>
            <td>
              <Multiselect
                      selectedValues={contentTargets}
                      isObject={false}     
                      showArrow={true}   
                      onRemove={onContentTargetsChange}
                      onSelect={onContentTargetsChange}
                      options={constants.contentTarget.value}/>
            </td>
          </tr>
          <tr>
            <th>Creator Name/Channel: </th>
            <td>
            <Multiselect
              selectedValues={creatorName}
                isObject={false}   
                showArrow={true}     
                onRemove={onCreateChange}
                onSelect={onCreateChange}
                onSearch={onCreatorSearch}
                selectionLimit={1}
                options={creatorsSuggection}/>
              <div>
                  <input style={{width: '100%'}} type="text" value={creatorName} onChange={onCreatorTextChange} />
              </div>
            </td>
          </tr>
          <tr>
            <th>Others: </th>
            <td>
            <Multiselect
              selectedValues={others}
                isObject={false}   
                showArrow={true}  
                selectionLimit={1}   
                onRemove={onOthersChange}
                onSelect={onOthersChange}                
                onSearch={onOthersSearch}
                options={othersSuggestion}/>    
                <div>
                  <input style={{width: '100%'}} type="text" value={others} onChange={onOthersTextChange} />
                </div>          
            </td>
          </tr>
          <tr>
            <th>Score</th>
            <td>                      
             <HorizontalSelect 
                options={constants.score.value}
                selectedValues={score}
                onRemove={onScoreChange}
                onSelect={onScoreChange}
                />
            </td>
          </tr>
          <tr>
            <th>Tier</th>
            <td>                        
              <HorizontalSelect 
                options={constants.tiers.value}
                selectedValues={tier}
                onRemove={onTierChange}
                onSelect={onTierChange}
                />
            </td>
          </tr>          
        </Table>
      </td>
      <td style={{verticalAlign: 'middle'}}>        
        <div>
        {
          showSpinner ?  
            <Spinner animation="border" variant="primary" />
            : <Button onClick={saveVideoTag}>Save</Button>
        }
        </div>
        <div style={{marginTop: '10px'}}>
          <Button size="sm" onClick={onRejectVideo} variant="danger">Reject</Button>
        </div>
        { props.tagged &&
          <div style={{marginTop: '70px'}}>
            {
              showSpinner ?  
                <Spinner animation="border" variant="primary" />
                : <Button onClick={completeQC} variant="success" size="sm">QC Done</Button>
            } 
          </div>
        }
      </td>
    </tr>

  );
}

VideoTag.propTypes = {
  //tagged: PropTypes.bool
}

export default VideoTag
