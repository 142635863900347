import React from 'react'
import PropTypes from 'prop-types';
import './MessageContainer.scss'
import Message from 'components/chat/message/Message';
import { useState } from 'react';


const MessageContainer = ({chats, loadMore, sendMessage, name, setMessage, message, onMessageDelete}) => {    
    let [asStudent, setAsStudent] = useState(false);

    const onMessageChange = (e) => {
        setMessage(e.target.value);
    }

    const getMessage = () => {
       return chats && chats.map((chat, index) =>
            <Message onMessageDelete={onMessageDelete} key={chat.id} messageId={chat.id} name={chat.sentBy.fullName} creationTime={chat.postedTime} 
            message={chat.message} creatorReply={chat.sentBy.userId == chat.creatorId} profilePic={chat.sentBy.profilePicUrl} />
        );
    }

    const sendMessageHandler = () => {
        sendMessage(asStudent);
    }

    const onStudentSelectChange = (e) => {        
        setAsStudent(!asStudent);
    }

    const getButtonStyles = () => {
        return !message ? {pointerEvents: 'none', opacity: '0.4'} : {};
    } 

    console.log("chats ", chats);

  return (
    <div className='messageContainer'>
        <div className='msger'>
            <header className="msgerHeader">
                <b>{name}</b>                    
               {/* {chats && chats.length && <span className="loadMore" onClick={loadMore}>Load More</span> } */}
            </header>
           {
               getMessage()
           }
           <div className="msger-inputarea">
               <input type="text" value={message} class="msger-input" onChange={onMessageChange} placeholder="Enter your message..." />
               <span type="button" style={getButtonStyles()} class="msger-send-btn" onClick={sendMessageHandler}>Send</span>
           </div>
           <div className="sendAsStudent">
                <label>
                    <input type="checkbox" checked={asStudent} onChange={onStudentSelectChange} />
                    <span>As student</span>
                </label>
           </div>
        </div>
    </div>
  )
}

MessageContainer.propTypes = {
    chats: PropTypes.array.isRequired,
    loadMore: PropTypes.func.isRequired,
    sendMessage: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired
}

export default MessageContainer