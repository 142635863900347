import * as NetworkAdapter from '../common/axios';

export const fetchVideo = (params) => {   
    console.log("fetchVideo called!"); 
    let url = '/video/open-videos'
    return new Promise((resolve, reject) => {
        NetworkAdapter.fetchData.subsystem.get(null, 'platform', url, params, null, null, null, null, null, {
            onCallback: (resp) => {
                if (resp.success) {
                    resolve(resp.resp);
                } else {
                    reject(resp.resp);
                }
            }
        });
    })
}

export const updateVideoTag = (params) => { 
    console.log("updateVideoTag called!"); 
    let url = '/video/update-video-tag'
    return new Promise((resolve, reject) => {
        NetworkAdapter.fetchData.subsystem.post(null, 'platform', url, params, null, null, null, null, null, {
            onCallback: (resp) => {
                if (resp.success) {
                    resolve(resp.resp);
                } else {
                    reject(resp.resp);
                }
            }
        });
    })
}

export const addQC = (videoId) => { 
    console.log("addQC called!"); 
    let url = `/video/qc-video/${videoId}`
    return new Promise((resolve, reject) => {
        NetworkAdapter.fetchData.subsystem.post(null, 'platform', url, null, null, null, null, null, null, {
            onCallback: (resp) => {
                if (resp.success) {
                    resolve(resp.resp);
                } else {
                    reject(resp.resp);
                }
            }
        });
    })
}

export const rejectVideo = (videoId) => { 
    console.log("rejectVideo called!"); 
    let url = `/video/reject-video/${videoId}`;
    return new Promise((resolve, reject) => {
        NetworkAdapter.fetchData.subsystem.post(null, 'platform', url, {}, null, null, null, null, null, {
            onCallback: (resp) => {
                if (resp.success) {
                    resolve(resp.resp);
                } else {
                    reject(resp.resp);
                }
            }
        });
    })
}

export const getTagSuggestions = (params) => { 
    console.log("getTagSuggestions called!"); 
    let url = `/video/tags-suggestions`;
    return new Promise((resolve, reject) => {
        NetworkAdapter.fetchData.subsystem.get(null, 'platform', url, params, null, null, null, null, null, {
            onCallback: (resp) => {
                if (resp.success) {
                    resolve(resp.resp);
                } else {
                    reject(resp.resp);
                }
            }
        });
    })
}

export const filterOpenVideos = (params) => { 
    console.log("filterOpenVideos called!"); 
    let url = `/video/filter`;
    return new Promise((resolve, reject) => {
        NetworkAdapter.fetchData.subsystem.post(null, 'platform', url, params, null, null, null, null, null, {
            onCallback: (resp) => {
                if (resp.success) {
                    resolve(resp.resp);
                } else {
                    reject(resp.resp);
                }
            }
        });
    })
}