import React from 'react'
import PropTypes from 'prop-types'
import Pagination from 'components/videoTagConatiner/pagination/Pagination'
import Table from 'react-bootstrap/Table'
import { Container, Button } from 'react-bootstrap'
import VideoTag from 'components/videoTagConatiner/videoTag/VideoTag'
import { useState, useEffect } from 'react';
import {fetchVideo, filterOpenVideos} from 'components/videoTagConatiner/service'
import ErrorModal from 'components/common/modals/ErrorModal'
import SuccessModal from 'components/common/modals/SuccessModal'
import NavNar from 'components/common/navbar/NavNar';
import Filter from 'components/videoTagConatiner/filter/Filter';

const VideoTagConatiner = (props) => {
    const PAGE_SIZE = 20;
    const [videos, setVideos] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [message, setMessage] = useState("");
    const [disableNext, setDisableNext] = useState(true);
    
    console.log("props: ", props);

    // const videos = [{
    //     link: 'https://media.w3.org/2010/05/sintel/trailer_hd.mp4',
    //     title: 'title 1'
    // },{
    //     link: 'https://media.w3.org/2010/05/sintel/trailer_hd.mp4',
    //     title: 'title 2'
    // },{
    //     link: 'https://www.instagram.com/reel/CauWy2otSAb/?utm_medium=copy_link',
    //     title: 'title 3'
    // }];

    const renderVideo = () => {
        return videos.length && videos.map( (video, index) => 
           <VideoTag showModal={showModal} video={video} key={index} index={index}
            tagged={props.tagged} qced={props.qced} rejected={props.rejected} />
        )
    }

    const updatePageNo = () => {
        let pageNo = props?.pageNo || 1;
        pageNo = parseInt(pageNo);
        let params = {
            start: (pageNo - 1)*PAGE_SIZE,
            size: PAGE_SIZE,
            tagged: !!props.tagged,
            rejected: !!props.rejected,
            qced: !!props.qced
        }
        fetchVideo(params)
        .then((resp)=>{
            console.log("successful: ", resp);
            if(resp.length === 0) {
                showModal(false, "Videos not Found!");
            }  
            let disableNext = resp.length < PAGE_SIZE ? true : false;
            setVideos(resp);     
            setDisableNext(disableNext)       
        })
        .catch((err)=>{
            console.log("Error");
            showModal(false, err);
        })
    }

    const getFilteredVideos = (params) => {
        if(props.tagged) {
            params.status = "TAGGED";
        } else if(props.qced) {
            params.status = "QCED";
        }
        filterOpenVideos(params)
        .then((resp) => {
            setVideos([]);
            setTimeout(() => {
                setVideos(resp); 
            });            
            console.log("successful filterOpenVideos: ", resp);           
        })
        .catch((err) => {
            props.showModal(false, err);
            console.log("Error");
        }).finally(() => {})
    }

    const showModal = (success, message) => {
        if(success) {
            setShowSuccessModal(true)
        } else {
            setShowErrorModal(true)
        }
        setMessage(message);
    }

    const hideModal = () => {
        setShowSuccessModal(false)
        setShowErrorModal(false)
    }

  return (
    <React.Fragment>        
        <Container> 
            <NavNar />
            { (props.tagged || props.qced) &&
                <Filter showModal={showModal} onFilterSubmit={getFilteredVideos}/> 
            }            
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Video</th>
                        <th>Tags</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>                
                    {renderVideo()}                
                </tbody>
            </Table>       
            <Pagination updatePageNo={updatePageNo} disableNext={disableNext} />
            {showSuccessModal && <SuccessModal hideModal={hideModal} message={message} />}
            {showErrorModal && <ErrorModal hideModal={hideModal} message={message}  />}
        </Container>
    </React.Fragment>
  )
}

VideoTagConatiner.propTypes = {
    tagged: PropTypes.bool
}

export default VideoTagConatiner
