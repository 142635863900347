import React from 'react'
import PropTypes from 'prop-types'
import './HorizontalSelect.scss';
import { Button } from 'react-bootstrap'
import { useState, useEffect } from 'react';

const HorizontalSelect = (props) => {
  const [optionMap, setOptionMap] = useState({});

  const onSelect = (e) => {
    const selected = e.target.textContent.trim();
    console.log(selected);
    let _optionMap = { ...optionMap }
    if (typeof (_optionMap[selected]) !== 'undefined') {
      _optionMap[selected] = !_optionMap[selected];
      setOptionMap(_optionMap);      
      if(_optionMap[selected]) {
        props.onSelect(getSelectedOptions(_optionMap), selected);
      } else {
        props.onRemove(getSelectedOptions(_optionMap), selected);
      }      
    }
  }

  const getSelectedOptions = (_optionMap) => {
    const allOptions = Object.keys(_optionMap);
    let selectedOptions = [];
    allOptions.map(option => {
      if (_optionMap[option]) {
        selectedOptions.push(option);
      }
    });
    return selectedOptions;
  }

  useEffect(() => {
    loadDataOnlyOnce();
  }, []);

  const loadDataOnlyOnce = () => {
    let optionMap = {};
    props.options.length && props.options.map((option, index) => {
      if (props.selectedValues && props.selectedValues.includes(option)) {
        optionMap[option] = true;
      } else {
        optionMap[option] = false;
      }
    });
    setOptionMap(optionMap);
  }

  const getButtons = () => {
    return Object.keys(optionMap).length && Object.keys(optionMap).map((option, index) =>
      <Button variant="outline-primary" className={optionMap[option] ? 'selected' : ''}
        size="sm" key={index} onClick={onSelect} > {option} </Button>
    );
  };

  return (
    <React.Fragment>
      <div className="select-conatiner">
        {getButtons()}
      </div>
    </React.Fragment>
  )
}

HorizontalSelect.propTypes = {
  options: PropTypes.array.isRequired,
  selectedValues: PropTypes.array.isRequired,
  onRemove: PropTypes.func,
  onSelect: PropTypes.func.isRequired
}

export default HorizontalSelect
