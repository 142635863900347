import React from 'react'
import PropTypes from 'prop-types'
import VideoTagConatiner from 'components/videoTagConatiner/VideoTagConatiner'

function TaggedVideo(props) {
    const tagged = true;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
  return (
    <VideoTagConatiner pageNo={params.page || 1} tagged={tagged} />
  )
}

TaggedVideo.propTypes = {}

export default TaggedVideo
