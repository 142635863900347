import React from 'react'
import PropTypes from 'prop-types'
import './Message.scss'

const Message = ({creatorReply, name, creationTime, message, profilePic, messageId, onMessageDelete}) => {
    const pad2 = (n) => { return n < 10 ? '0' + n : n }
    const getTime = (creationTime) => {
        var date = new Date(creationTime);
        return date.getFullYear().toString() +"/"+ pad2(date.getMonth() + 1) +"/"+ pad2( date.getDate()) +" "+ pad2( date.getHours() ) +":"+ pad2( date.getMinutes() +":"+pad2( date.getSeconds() ));
    }

    const messageDeleteHandler = () => {
        onMessageDelete(messageId);
    }

    return (
        <div className={`MessageWrapper ${creatorReply ? 'rightMsg' : ''}`}>
            <div className='msgImg' style={{backgroundImage: profilePic}}>
                <img className='profilePic' alt="pic" src={profilePic} />
            </div>
            <div className="msgBubble">
                <div className="msgInfo">
                    <div className="msg-info-name"><b>{name}</b></div>                    
                    <div className="msg-info-time">{getTime(creationTime)}</div>
                </div>                
                <div className="msg-text">
                    {message}
                </div>
                <img style={{float: 'right', cursor: 'pointer'}} src="./images/delete-48.png" height="20" alt="delete" onClick={messageDeleteHandler} />
            </div>
        </div>
    )
}

Message.propTypes = {
    creatorReply: PropTypes.bool
}

export default Message