import React from 'react'
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import {getCreatorList} from 'components/creators/service';
import Creator from 'components/creators/creator/Creator';
import './Creators.scss'
import Button from 'react-bootstrap/Button';

function Creators({selectedCreator, onCreatorSelect, creatorList, onCreatorEmailSubmit}) {        
    let [creatorEmail, setCreatorEmail] = useState("");

    const getCreators = () => {
        return creatorList && creatorList.length && creatorList.map( (creator, index) => 
           <Creator onCreatorSelect={onCreatorSelect} isSelected={creator.userId === selectedCreator}
                fullName={creator.fullName} profilePicUrl={creator.profilePicUrl} key={creator.userId} id={creator.userId} />
        )
    }

    const onEmailChange = (e) => {
      setCreatorEmail(e.target.value);
    }

    const getButtonStyles = () => {
      return !creatorEmail ? {pointerEvents: 'none', opacity: '0.4'} : {};
    } 

    const onSubmit = () => {
      if(!/\S+@\S+\.\S+/.test(creatorEmail)) {
        alert("Invalid Email");
        return;
      }
      onCreatorEmailSubmit(creatorEmail);
    }

  return (
    <div className="creators-conatiner">
      <div>
          {getCreators()}
      </div>
      <div className="onboard-section">
        <label for="creator-email-input">Onboard Creator</label>
        <input id="creator-email-input" type="email" required={true} value={creatorEmail} class="creator-email-input" onChange={onEmailChange} placeholder="Enter creator email..." />        
        <Button variant="primary" style={getButtonStyles()} onClick={onSubmit}>Submit</Button>
      </div>
    </div>
  )
}

Creators.propTypes = {
    selectedCreator: PropTypes.string.isRequired,
    onCreatorUpdate: PropTypes.func.isRequired,
    creatorList: PropTypes.array
}

export default Creators
