export const SELECT_TYPE = {
    SINGLE: 'SINGLE',
    MULTI: 'MULTI',
    TEXT: 'TEXT'
};

export default {
    grades: {
        type: SELECT_TYPE.MULTI,
        value: [
            '6-8', '9-10', '11-13', 'College', 'Universal'
        ]
    },
    langugae: {
        type: SELECT_TYPE.MULTI,
        value: [
            'English', 'Hindi', 'Hinglish', 'others'
        ]
    },
    state: {
        type: SELECT_TYPE.MULTI,
        value: [
            'Andaman and Nicobar (UT)', 'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chandigarh (UT)', 'Chhattisgarh', 'Dadra and Nagar Haveli (UT)', 'Daman and Diu (UT)', 'Delhi', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jammu and Kashmir', 'Jharkhand', 'Karnataka', 'Kerala', 'Lakshadweep (UT)', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Orissa', 'Puducherry (UT)', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal'
        ]
    },
    contentType: {
        type: SELECT_TYPE.MULTI,
        value: [
            'planning', 'inspirational', 'informative', 'motivational', 'career', 'habit formation', 'stress management', 'study tips', 'generic'
        ]
    },
    contentTarget: {
        type: SELECT_TYPE.MULTI,
        value: [
            'engineering', 'medical', 'exams', 'jobs/internship', 'creators', 'finance/stocks', 'crypto/web3', 'entrepreneurship', 'freelancing', 'life style', 'generic'
        ]
    },
    creatorName: {
        type: SELECT_TYPE.TEXT,
        value: null
    },
    tiers: {
        type: SELECT_TYPE.MULTI,
        value: [
            'TIER_1','TIER_2','TIER_3',
        ]
    },
    sources: {
        type: SELECT_TYPE.MULTI,
        value: [
            'YOUTUBE', 'INSTAGRAM'
        ]
    },
    score: {
        type: SELECT_TYPE.SINGLE,
        value: [
            1, 2, 3, 4, 5
        ]
    }
}