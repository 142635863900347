import React from 'react'
import PropTypes from 'prop-types'
import VideoTagConatiner from 'components/videoTagConatiner/VideoTagConatiner'

function QcedVideo(props) {
    const qced = true;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    return (
      <VideoTagConatiner pageNo={params.page || 1} qced={qced} />
    )
}

QcedVideo.propTypes = {}

export default QcedVideo
