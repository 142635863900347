import * as NetworkAdapter from '../common/axios';
import * as Utils from 'components/common/utils'

export const getChats = (params) => {       
    let url = '/engagement/discussions'
    return new Promise((resolve, reject) => {
        NetworkAdapter.fetchData.subsystem.get(null, 'platform', url, params, null, null, null, null, null, {
            onCallback: (resp) => {
                if (resp.success) {
                    resolve(resp.resp);
                } else {
                    reject(resp.resp);
                }
            }
        });
    })
}

export const addComments = (params) => {
    let url = '/engagement/tools/discussion'
    return new Promise((resolve, reject) => {
        NetworkAdapter.fetchData.subsystem.post(null, 'platform', url, params, null, null, null, null, null, {
            onCallback: (resp) => {
                if (resp.success) {
                    resolve(resp.resp);
                } else {
                    reject(resp.resp);
                }
            }
        });
    })
}

export const getAuthToken = (params) => {
    let url = '/firebase/auth-token'
    return new Promise((resolve, reject) => {
        NetworkAdapter.fetchData.subsystem.get(null, 'platform', url, params, null, null, null, null, null, {
            onCallback: (resp) => {
                if (resp.success) {
                    resolve(resp.resp);
                } else {
                    reject(resp.resp);
                }
            }
        });
    })
}