import * as NetworkAdapter from '../common/axios';

export const getUsers = (params) => {       
    let url = '/user/byIds';
    return new Promise((resolve, reject) => {
        NetworkAdapter.fetchData.subsystem.post(null, 'platform', url, params, null, null, null, null, null, {
            onCallback: (resp) => {
                if (resp.success) {
                    resolve(resp.resp);
                } else {
                    reject(resp.resp);
                }
            }
        });
    })
}