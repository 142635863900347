import { Route, Routes, BrowserRouter } from 'react-router-dom'
import './App.css';
import Login from './components/login/login'
import VideoTagConatiner from './components/videoTagConatiner/VideoTagConatiner'
import TaggedVideo from 'components/taggedVideo/TaggedVideo'
import RejectedVideo from 'components/rejectedVideo/RejectedVideo'
import QcedVideo from 'components/qcedVideo/QcedVideo'
import ChatMainContainer from 'components/chat/ChatContainer';
import ChatApp from 'components/chatV2/ChatApp';


function App() {
  return (
    <BrowserRouter>
      <Routes>      
        <Route exact path="/" element={<Login />}></Route>
        <Route exact path="/untagged-videos" element={<VideoTagConatiner />}></Route>
        <Route exact path="/tagged-videos" element={<TaggedVideo />}></Route>
        <Route exact path="/rejected-videos" element={<RejectedVideo />}></Route>
        <Route exact path="/qced-videos" element={<QcedVideo />}></Route>
        {/* <Route exact path="/chat" element={<ChatMainContainer />}></Route> */}
        <Route exact path="/chat" element={<ChatApp />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
