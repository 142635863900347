import React from 'react'
import PropTypes from 'prop-types'
import Nav from 'react-bootstrap/Nav'
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import * as Utils from 'components/common/utils'

function NavNar(props) {
    const COOKIE_NAME = 'accessToken';
    const navigate = useNavigate();

    useEffect(() => {             
        setTimeout(() => {
            let access = Utils.getCookie(COOKIE_NAME);
            console.log("access: ", access);  
            if(!access) {
                navigate('/');
            }
        }, 100);
    }, []);

    const logout = () => {
        Utils.eraseCookie(COOKIE_NAME)
        setTimeout(() => {
            navigate('/');
        }, 100);        
    }


  return (
    <Nav className="justify-content-center" defaultActiveKey="/" as="ul">
        <Nav.Item as="li">
            <Nav.Link href="/" onClick={logout}>Logout</Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
            <Nav.Link eventKey="link-1" href="/untagged-videos">Untagged Videoss</Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
            <Nav.Link eventKey="link-2" href="/tagged-videos">Tagged Videos</Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
            <Nav.Link eventKey="link-2" href="/rejected-videos">Rejected Videos</Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
            <Nav.Link eventKey="link-2" href="/qced-videos">QCed Videos</Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
            <Nav.Link eventKey="link-2" href="/chat">Chat</Nav.Link>
        </Nav.Item>
    </Nav>
  )
}

NavNar.propTypes = {}

export default NavNar
