import axios from 'axios';
import * as Utils from 'components/common/utils'

export const fetchData = {
    subsystem: {
        get: function (dispatch, subsystem, path, params, loadingAction, successAction, errorAction, 
          ignoreError, checkParamsForError, extraData) {
            var url = "";
            url = fetchData.formSubSystemPath(subsystem, path);
            fetchData.get(dispatch, url, params, loadingAction, successAction, errorAction, ignoreError, checkParamsForError, extraData);
        },
        post: function (dispatch, subsystem, path, params, loadingAction, successAction, errorAction, 
          ignoreError, checkParamsForError, extraData) {
            var url = "";
            url = fetchData.formSubSystemPath(subsystem, path);
            console.log("URL: ", url);
            return fetchData.postJson(dispatch, url, params, loadingAction, successAction, errorAction, ignoreError, checkParamsForError, extraData);
        }
    },
    formSubSystemPath: function (subsystem, path) {
        if (typeof window.config === "undefined") {
          return "";
        }
        const pConf = window.config?.backend[subsystem];
        if (!!pConf) {          
          return pConf.domain + pConf.folder + path;          
        }
    },
    makeRequest: function (dispatch, reqObj, loadingAction, successAction, errorAction, ignoreError, extraData) {
        if (dispatch && loadingAction) dispatch(loadingAction(true));
        axios(reqObj)
            .then((response) => {
                if (dispatch && loadingAction) dispatch(loadingAction(false));

                const onCallback = extraData && extraData.onCallback;
                if (onCallback) {
                    return onCallback({
                        success: true,
                        resp: response.data,
                        extraData
                    });
                } else if (dispatch && successAction) {
                    dispatch(successAction(response.data, extraData));
                }
            })
            .catch((e) => {                
                if (e.response ?. status === 401 && e.response ?. data ?.errorMessage === "NOT_LOGGED_IN") {
                    Utils.eraseCookie('accessToken')
                    // alert("logout")
                    window.location.href = "/"
                } else if (e.message && e.message === 'Operation canceled by the user.') {
                    return
                } else {
                    if (dispatch && loadingAction) dispatch(loadingAction(false));
                    let errorData = null;
                    if (e.response) {
                        errorData = e.response.data;
                    }
                    const onCallback = extraData && extraData.onCallback;
                    if (onCallback) {
                        return onCallback({
                            success: false,
                            resp: errorData,
                            extraData
                        });
                    } else {
                        if (errorAction) dispatch(errorAction(errorData, extraData));
                    }
                }
            });
    },
    postJson: function (dispatch, url, params, loadingAction, successAction, errorAction, ignoreError, checkParamsForError, extraData) {
        var reqObj = {
            method: "POST",
            url: url,
            withCredentials: true,
            data: fetchData.prepareParams(params, checkParamsForError),
            headers: {
                'Content-Type': 'application/json'
            }
        }
        fetchData.makeRequest(
            dispatch,
            reqObj,
            loadingAction, successAction, errorAction,
            ignoreError, extraData
        );
    },
    get: function (dispatch, url, params, loadingAction, successAction, errorAction, ignoreError, checkParamsForError, extraData, withCredentials = true) {
        var reqObj = {
            method: "GET",
            url: url,
            withCredentials: withCredentials,
            params: fetchData.prepareParams(params, checkParamsForError),
            headers: {}
        }
        fetchData.makeRequest(
            dispatch,
            reqObj,
            loadingAction, successAction, errorAction,
            ignoreError, extraData);
    },
    prepareParams: function (params, checkParamsForError) {
        params = params ? params : {};
        if (checkParamsForError) {
            params = fetchData.enumerateParam(params);
        }
        return params;
    },
    enumerateParam: function (param) {
        param.forEach(function (value, key) {
            if (typeof value === "undefined" || value === null) {
                delete param[key];
            } else if (typeof value === "object") {
                value = fetchData.enumerateParam(value);
            }
        });
        return param;
    }
}