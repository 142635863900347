import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from 'react';
import './modal.scss';

function ErrorModal(props) {
  const handleClose = () => props.hideModal();
  
  return (
      <Modal show={true} onHide={handleClose} className="error-model">
        <Modal.Header closeButton className="error-modal-header">
          <Modal.Title>{props.title || 'Error'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
  )
}

ErrorModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string.isRequired
}

export default ErrorModal
