import React from 'react'
import PropTypes from 'prop-types'
import Multiselect from 'multiselect-react-dropdown';
import Table from 'react-bootstrap/Table'
import { useState } from 'react'
import {getTagSuggestions} from 'components/videoTagConatiner/service';
import constants from 'components/videoTagConatiner/constants';
import HorizontalSelect from 'components/common/horizontalSelect/HorizontalSelect';
import { Button } from 'react-bootstrap'
import { trimArray } from 'components/common/utils';

function Filter(props) {    
    const [tier, setTier] = useState([]);
    const [grades, setGrades] = useState([]);
    const [score, setScore] = useState([]);              
    const [others, setOthers] = useState([]);
    const [creatorName, setCreatorName] = useState([]);
    const [creatorsSuggection, setCreatorsSuggection] = useState([]);
    const [othersSuggestion, setOthersSuggestion] = useState([]);
    
    const onCreateChange = (selectedList, removed) => {
        setCreatorName(selectedList)
    }
    
    const onOthersChange = (selectedList, removed) => {
        setOthers(selectedList)
    }

    const onCreatorSearch = (query) => {
        if (query.length < 3) {
            return
        }
        let params = {
            query: query,
            type: 'CREATOR_NAME'
        };
        console.log(query);
        getTagSuggestions(params)
            .then((resp) => {
                console.log("successful onCreatorSearch: ", resp);
                setCreatorsSuggection(resp.responseObject)
            })
            .catch((err) => {
                props.showModal(false, err);
                console.log("Error");
            }).finally(() => {})
    }
      
    const onOthersSearch = (query) => {
        if (query.length < 3) {
            return
        }
        let params = {
            query: query,
            type: 'OTHERS'
        };
        console.log(query);
        getTagSuggestions(params)
            .then((resp) => {
                console.log("successful onOthersSearch: ", resp);
                setOthersSuggestion(resp.responseObject)
            })
            .catch((err) => {
                props.showModal(false, err);
                console.log("Error");
            }).finally(() => {})
    }

    const onGradeChange = (selectedList, removed) => {
        setGrades(selectedList)
        console.log("selectedList: ", selectedList);
    }

    const onScoreChange = (selectedList) => {
        setScore(selectedList)
    }

    const onTierChange = (selectedList, removed) => {
        setTier(selectedList)
    }
    
   const onFilter = () => {
       let _others = trimArray(others);
       let _creatorNames = trimArray(creatorName);
       let params = {
           'tiers': tier.length ? tier: null,
           'grades': grades.length ? grades: null,
           'scores': score.length ? score : null,
           'others': _others.length ? _others : null,
           'creatorNames': _creatorNames.length ? _creatorNames: null
       };
       console.log("onFilter called!", params);
       props.onFilterSubmit(params)       
   }

  return (
    <div>
       <Table bordered>
            <thead>
                <tr>                   
                    <th>Creator</th>
                    <th>Others</th>
                    <th>Grades</th>
                    <th>Score</th>
                    <th>Tier</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>                
                <tr>
                    <td>                    
                        <Multiselect
                            selectedValues={creatorName}
                            isObject={false}   
                            showArrow={true}     
                            onRemove={onCreateChange}
                            onSelect={onCreateChange}
                            onSearch={onCreatorSearch}
                            selectionLimit={1}
                            options={creatorsSuggection} />                        
                    </td>
                    <td>
                        <Multiselect
                            selectedValues={others}
                            isObject={false}   
                            showArrow={true}                                           
                            onRemove={onOthersChange}
                            onSelect={onOthersChange}
                            onSearch={onOthersSearch}
                            selectionLimit={1}
                            options={othersSuggestion} />    
                    </td>
                    <td style={{width: '250px'}}>
                        <HorizontalSelect 
                            options={constants.grades.value}
                            selectedValues={grades} 
                            onRemove={onGradeChange}
                            onSelect={onGradeChange}
                        />
                    </td>
                    <td style={{width: '200px'}}>                      
                        <HorizontalSelect 
                            options={constants.score.value}
                            selectedValues={score}
                            onRemove={onScoreChange}
                            onSelect={onScoreChange}
                        />
                    </td>
                    <td style={{width: '200px'}}>      
                        <HorizontalSelect 
                            options={constants.tiers.value}
                            selectedValues={tier}
                            onRemove={onTierChange}
                            onSelect={onTierChange}
                        />
                    </td>
                    <td>
                        <Button onClick={onFilter}>Filter</Button>
                    </td>
                </tr>
            </tbody>
        </Table>    
    </div>
  )
}

Filter.propTypes = {
    onFilterSubmit: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired
}

export default Filter
