import React from 'react'
import PropTypes from 'prop-types'
import './Creator.scss';

const Creator = ({fullName, profilePicUrl, onCreatorSelect, id, isSelected}) => {
  const DEFAULT_PIC = "/man.png"
  let imageRef = React.createRef();  

  const loadDefaultImage = () => {
    imageRef.current.src = DEFAULT_PIC;  
  }

  const onSelect = () => {    
    onCreatorSelect(id, fullName)
  }

  return (
    <div className={isSelected ? 'creatorContainer creatorActive' : 'creatorContainer'} onClick={onSelect}>
        <div>
            <img ref={imageRef} alt='profile pic' src={profilePicUrl} 
              onError={loadDefaultImage} style={{width: 100, height: 100, borderRadius: "50%"}} />
        </div>
        <div>
          {fullName}
        </div>
        {/* <div>{id}</div> */}
    </div>
  )
}

Creator.propTypes = {
    fullName: PropTypes.string.isRequired,
    profilePicUrl: PropTypes.string.isRequired,
    onCreatorSelect: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired
}

export default Creator