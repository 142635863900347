export const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }

    if (window.config.mode === "local") {
        document.cookie = name + "=" + (value || "") + expires + ";path=/";
    } else {
        document.cookie = name + "=" + (value || "") + expires + ";domain=.thewagmi.app;path=/";
    }
}

export const getCookie = (name) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export const eraseCookie = (name) => {
    if (window.config.mode === "local") {
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    } else {
        document.cookie = name + '=; domain=.thewagmi.app; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
}

export const trimArray = (arr) => {
    return arr && arr.map(element => {
        if (typeof element === 'string') {
          return element.trim();
        }
        return element;
    });    
}

export const getUrlParams = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return Object.fromEntries(urlSearchParams.entries());
}